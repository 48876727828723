@import "../../styles/colors.scss";
@import "../../styles/flex.scss";

.dashboard-wrapper {
  background: $color-light-blue;
  height: 100vh;
  width: 100vw;
  @include flexContainerCols;
  font-family: "Poppins", sans-serif;
}

.dashboard-bottom-container {
  @include flexContainerRows;
  height: 100%;
  width: 100%;

  .dashboard-main {
    position: relative;
    height: 100%;
    width: 100%;


    .dashboard-main-scrollContainer {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      @include flexContainerRows;
      flex-wrap: wrap;
      overflow-y: scroll;
    }
  }
}
