@import "../../../styles/flex.scss";
@import url("https://fonts.googleapis.com/css2?family=Kaushan+Script&display=swap");

.badge-container {
  @include flexContainerCols;
  align-items: center;

  .badge-top {
    cursor: pointer;
    height: 150px;
    width: 150px;
    @include flexContainerRows;
    justify-content: center;
    position: relative;
    margin-bottom: 1em;

    .badge-placeholder {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
      max-width: 100%;
      max-height: 100%;
    }

    .badge-activeBadge {
      transform-origin: center;
      position: absolute;
      z-index: 2;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 90%;
      max-height: 90%;

      &:hover {
        animation: badgeAnimation 2s ease-in-out infinite;
      }
    }

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}

.badgeCard {
  background: rgb(16, 22, 34);
  color: black;
  height: 400px;
  width: 250px;
  box-shadow: 0px 0px 30px rgb(0, 255, 145);
  border-radius: 0.5em;
  position: relative;
  font-family: "Poppins", sans-serif;
  @include flexContainerCols;
  justify-content: flex-end;
  padding: 2em;

  .badgeCard-close {
    cursor: pointer;
    position: absolute;
    top: -50px;
    right: -100px;
  }

  .shine {
    content: "";
    height: 200px;
    width: 200px;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: shineAnimation 3s ease-in-out infinite;
  }

  .badge-gem {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .badge-desc-headding {
    text-align: center;
    font-family: "Kaushan Script", cursive;
    margin-bottom: 1em;
  }

  .badge-desc {
    border-top: 2px solid #b6b6b6;
    border-bottom: 2px solid #b6b6b6;
    padding: 0.5em 0;
    color: #b6b6b6;
  }

  .badge-desc-number {
    margin-top: 1em;
    color: #ffc248;
  }
}

@keyframes badgeAnimation {
  0% {
    max-width: 90%;
    max-height: 90%;
  }
  50% {
    max-width: 80%;
    max-height: 80%;
  }
  100% {
    max-width: 90%;
    max-height: 90%;
  }
}

@keyframes shineAnimation {
  0% {
    height: 200px;
    width: 200px;
  }
  50% {
    height: 220px;
    width: 220px;
  }
  100% {
    height: 200px;
    width: 200px;
  }
}
