@import "../../styles/flex.scss";

.editProfile-container {
  height: 100vh;
  width: 100vw;
  @include flexContainerCols;
  justify-content: center;
  align-items: center;
  background: rgb(211, 211, 255);

  .tempCard {
    height: 500px;
    width: 700px;
    background: rgb(214, 81, 51);
    box-shadow: 10px 10px 10px rgba(255, 107, 73, 0.631);
    @include flexContainerCols;
    justify-content: flex-start;
    padding: 2em;

    .tempCard-item {
      @include flexContainerCols;

      span {
        font-family: "Poppins", sans-serif;
      }
      input {
          width: 300px;
          height: 2em;
          border: none;
          margin-bottom: 2em;
      }
    }

    button {
        width: 200px;
        padding: 1em 2em;
        border:none;
        background: white;
        font-family: "Poppins", sans-serif;
    }
  }
}
