@import "../../styles/flex.scss";

.authTile-wrapper {
  height: 100vh;
  width: 100vw;
  @include flexContainerCols;
  justify-content: center;
  align-items: center;
  padding: 10em;

  a {
    &:visited {
      color: black;
    }
  }

  .auth-home-button {
    position: fixed;
    top: 1em;
    right: 1em;

    span {
      cursor: pointer;
      font-size: 3em;
    }
  }

  .authCard-container {
    height: 100%;
    max-height: 600px;
    min-height: 580px;
    width: 100%;
    max-width: 1300px;
    min-width: 1000px;
    box-shadow: 10px 10px 10px #0000004b;
    @include flexContainerRows;
    justify-content: center;
    align-items: center;
    border-radius: 0.25em;
    overflow: hidden;

    .authLeft-container {
      background: white;
      height: 100%;
      width: 50%;
      padding: 4em 4em;
      @include flexContainerCols;
      justify-content: space-between;
      align-items: flex-start;
      font-family: "Poppins", sans-serif;

      .auth-error {
        background: rgb(255, 124, 124);
        border-radius: 0.25em;
        padding: 0.5em;
      }

      h1 {
        font-weight: 500;
        text-decoration: underline;
        margin-bottom: 0.25em;
      }

      .auth-formItem {
        @include flexContainerCols;
        margin-bottom: 1em;
        width: 100%;

        .auth-label-with-icon {
          @include flexContainerRows;
          align-items: baseline;
          position: relative;
          
          span {
            margin-right: 1em;
          }
          
          .passwordInfo {
            position: absolute;
            top: 8px;
            left: 110px;
            width: 400px;
            background: #ff8098;
            border-radius: 0.25em;
            color: white;
            @include flexContainerCols;
            box-shadow: 10px 10px 10px hsla(349, 100%, 50%, 0.7);
            
            span {
              cursor: pointer;
              align-self: flex-end;
              padding: 1em 1em 0em 1em;
              color: white;
            }

            p {
              margin: 0em 2em 2em 2em;
            }
          }
        }

        span {
          margin-bottom: 0.5em;
          color: black;
        }

        input {
          height: 3em;
          width: 100%;
          border: none;
          border-radius: 0.25em;
          text-indent: 1em;
          color: black;

          &:focus {
            outline: none;
          }
        }
      }

      .auth-button-container {
        @include flexContainerRows;
        justify-content: space-between;

        button {
          cursor: pointer;
          padding: 1em 2em;
          font-family: "Poppins", sans-serif;
          font-weight: 700;
          color: white;
          border-radius: 0.25em;
          border: none;
          margin-bottom: 2em;
        }
      }

      .authLink-container {
        @include flexContainerRows;
        justify-content: center;
        align-items: center;
        width: 100%;
        a{
          text-decoration: none;
           &:hover {
             text-decoration: underline;
           }
        }

        span {
          color: black;
        }

        > span > span {
          margin-left: 2em;
        }
      }
    }

    .authRight-container {
      height: 100%;
      width: 50%;
      @include flexContainerCols;
      justify-content: space-between;

      img {
        object-fit: cover;
        height: 100%;
      }
    }
  }
}

.auth-header {
  width: 100%;
  margin-bottom: 2em;
}
.auth-body {
  width: 100%;
}
.auth-footer {
  width: 100%;
}

@media screen and (max-width: 1080px) {
  .authTile-wrapper {
    padding: 0;
    width: 100vw;

    .auth-home-button {
      position: fixed;
      top: 1em;
      right: 1em;

      span {
        cursor: pointer;
        font-size: 3em;
        color: white;
      }
    }

    .authCard-container {
      @include flexContainerColsReverse;
      max-height: 100%;
      max-width: 100%;
      min-width: 0;
      min-height: 700px;

      .authRight-container {
        width: 100%;
        max-width: 100%;
        height: 40%;
      }
      .authLeft-container {
        width: 100%;
        max-width: 100%;

        padding-right: 200px;
        padding-left: 200px;
      }
    }
  }
}

@media screen and (max-width: 750px) {
  .authTile-wrapper {
    .authCard-container {
      .authLeft-container {
        padding-right: 4em;
        padding-left: 4em;

        .auth-formItem {
          span {
            font-size: 1.25em;
          }

          input {
            font-size: 1.25em;
          }

          .auth-label-with-icon {
            .passwordInfo {
              position: absolute;
              top: 2em;
              left: 0;
            }
          }
        }

        button {
          font-size: 1.25em;
        }
      }
    }
  }
}
