@import "../../../styles/flex.scss";
@import "../../../styles/colors.scss";

.dashboardItem-container {
  cursor: pointer;
  width: 200px;
  height: 200px;
  border-radius: 0.5em;
  background: $color-mid-blue;
  @include flexContainerCols;
  margin: 1em;
  padding: 1em;

  &:hover {
      background: $color-dark-blue;
  }

  .dashboardItem-main {
      margin-top: 1em;
      @include flexContainerRows;
      justify-content: center;
      align-items: center;
  }
}
