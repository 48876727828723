@import '../../styles/colors.scss'; 

.container {
  width: 100%;
  height: 100vh;
  background: $color-light-blue;
}

.homePage-container {
  display: flex;
  flex-direction: column;
}

.cot-container {
  // position: fixed;
  // top:25%;
  // left:auto;
  margin: auto;
padding: 2em 4em;
border-radius: 0.5em;
background: rgba(0, 0, 0, 0.4);
max-width: 600px;
backdrop-filter: blur(10px);

h1 {
  color: white;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  margin-bottom: 0.1em;
}

h3 {
  color: white;
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  margin-bottom: 3em;
}

p {
    color: white;
    font-family: "Poppins", sans-serif;
    font-weight: 200;
    margin-bottom: 1em;
}
}
