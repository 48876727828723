@import "../../../styles/flex.scss";

@import url("https://fonts.googleapis.com/css2?family=Kaushan+Script&family=Lobster&display=swap");

.homeSection {
  height: 100vh;
  @include flexContainerRows;
  justify-content: center;
  align-items: center;
  margin: 0 6em;
  font-family: "Poppins", sans-serif;
}

.homeSection-mid-container {
  width: 100vw;
  height: 80%;
  border-radius: 1em;
  @include flexContainerRows;
  background: white;
  box-shadow: 10px 10px 10px #090b4459;

  .homesection-preview-left {
    @include flexContainerCols;
    justify-content: space-between;
    width: 50%;
    padding: 4em;
    position: relative;

    h1 {
      position: absolute;
      top: -70px;
      left: -40px;
      font-size: 6em;
      font-family: "Kaushan Script", cursive;
      font-family: "Lobster", cursive;
      text-shadow: 5px 5px 5px #090b4459;
    }

    .homesection-description {
      padding-top: 2em;
      font-size: 1.2em;
      width: 70%;
      color: rgb(34, 34, 34);
      p {
        padding-bottom: 3em;
      }
    }

    .homeSection-button {
      cursor: pointer;
      padding: 1em 1em;
      border: none;
      border-radius: 0.5em;
      text-decoration: none;
      font-size: 1.2em;
      width: fit-content;
      background: #c70024;
      color: white;
    }
  }

  .homesection-preview-right {
    @include flexContainerCols;
    border-top-right-radius: 1em;
    border-bottom-right-radius: 1em;
    width: 50%;
    height: 100%;
    // background: #c70024;
    position: relative;
    overflow: hidden;
  }
}
