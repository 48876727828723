@import "../../styles/flex.scss";
@import "../../styles/colors.scss";
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

.login-container {
  @include flexContainerRows;
  align-items: center;
  font-family: "Poppins", sans-serif;

  .login-icon {
    content: "";
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background: red;
    font-size: 2em;
  }

  .login-button {
    cursor: pointer;
    color: grey;
    display: flex;
    align-items: center;

    a {
      text-decoration: none;

      &:visited {
        color: grey;
      }

      &:hover {
        color: black;
      }
    }

    &:hover {
      text-decoration: underline;
      color: black;
    }

    &:active {
      color: $color-dark;
    }
  }
}
