.scrollIndicator-container {
    position:absolute;
    bottom: 2em;
    left: 50%;

    .indicator {
        content: "";
        border-radius: 50%/25%;
        height: 80px;
        width: 40px;
        border: 0.25em solid hsl(205, 100%, 72%);
        display: flex;
        justify-content: center;

        .indicator-inside {
            content: "";
            height: 25px;
            width: 25px;
            border-radius: 50%;
            background: hsl(205, 100%, 72%);
            margin-top: 0.25em;
        }
    }
}