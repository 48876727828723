@import "../../styles/colors.scss";
@import "../../styles/flex.scss";

.badges-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.645);
  padding: 5em 10em;

  .badges-container {
    @include flexContainerCols;
    justify-content: flex-start;
    background: $color-light-blue;
    color: black;
    border-radius: 0.5em;
    height: 100%;
    width: 100%;
    padding: 2em;
    font-family: "Poppins", sans-serif;
    font-weight: 400;

    .badges-header {
      @include flexContainerRows;
      justify-content: space-between;
      margin-bottom: 2em;
    }

    .badges-main {
      @include flexContainerRows;
      justify-content: flex-start;
      flex-wrap: wrap;
      overflow-y: scroll;
      height: 100%;

      .badges-singleBadge {
        @include flexContainerCols;
        align-items: center;
      }
    }
  }
}

.isolatedBadge-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 4;
  background: rgba(0, 0, 0, 0.597);
  @include flexContainerCols;
  justify-content: center;
  align-items: center;
}

::-webkit-scrollbar {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: $color-dark-blue;
  border-radius: 0.5em;
}
