@import '../../styles/flex.scss'; 
@import '../../styles/colors.scss'; 

.profileBox-container {
    width: 100%;
    background: $color-mid-blue;
    @include flexContainerCols;
    justify-content: center;
    padding: 2em;
    align-items: center;

    .profileBox-profilePicture {
        height: 100px;
        width: 100px;        
        img {
            object-fit: cover;
            height: 100px;
            width: 100px;
            border-radius: 50%;
            border: 4px solid $color-dark-blue;
        }
    }

    .profileBox-profileName {
        margin-top: 1em;
    }

    border-bottom: 2px solid $color-dark-blue;
    border-top: 2px solid $color-dark-blue;
}