@import "../../../styles/flex.scss";

.checkbox-container {
  cursor: pointer;

  .visibleCheckbox {
    content: "";
    height: 40px;
    width: 80px;
    background: rgb(192, 192, 192);
    border-radius: 20%/50%;
    position: relative;
  }

  .visibleCheckbox-indicator {
    content: "";
    position: absolute;
    top: 50%;

    transform: translate(10%, -50%);
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background: rgb(255, 255, 255);
  }

  .animate-false {
    animation: falseAnimateion 0.5s ease-in;
  }
  .animate-true {
    animation: trueAnimateion 0.5s ease-in;
    left: 40px;
    background: red;
  }
}

@keyframes trueAnimateion {
  0% {
    left: 0;
    background: white;
  }
  50% {
    background: red;
  }
  100% {
    left: 40px;
  }
}

@keyframes falseAnimateion {
  0% {
    left: 40px;
  }
  100% {
    left: 0;
  }
}
