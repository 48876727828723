@import '../../../styles/flex.scss'; 
@import '../../../styles/colors.scss'; 

.dashboardHeader-container {
    height: 5em;
    background: $color-mid-blue;
    overflow: hidden;
    font-family: "Poppins", sans-serif;
    @include flexContainerRows;
    align-items: center;
    justify-content: space-between;
    padding: 2em 5em 2em 2em;

    .dashboardHeader-MenuToggle {
        font-size: 2em;
        cursor: pointer;
    }

    span {
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }

}
