@import '../../styles/flex.scss';


.navigation-container {
    @include flexContainerRows;
    justify-content: space-between;
    flex-grow: 1;
    min-width: 0;
    padding: 0 9em;;
}

@media screen and (max-width: 1600px) {
    .navigation-container {
      padding: 2em 4em;
    }
  }

@media screen and (max-width: 1080px) {
    .navigation-container {
      padding: 2em 2em;
    }
  }

@media screen and (max-width: 900px) {
    .navigation-container {
      display: none;
    }
  }


