@import '../../../styles/colors.scss';
@import '../../../styles/flex.scss';
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap');

.registerButton-container {
    @include flexContainerRows;
    justify-content: center;
    align-items: center;
    background: $color-dark;
    width: 180px;
    height: 42px;
    border-radius: 23px;
    cursor: pointer;
    margin-left: 1em;
}
.registerButton-label {
    background: transparent;
    color: white;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
}