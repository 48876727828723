@import '../../../styles/colors.scss';
@import '../../../styles/flex.scss';

.header-container {
  @include flexContainerRows;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: $color-white;
  padding: 2em 12em;
  height: 5em;
  z-index: 2;

  .logo {
    height: 2.5em;
  }
}

@media screen and (max-width: 1600px) {
  .header-container {
    padding: 2em 6em;
  }
}

@media screen and (max-width: 1080px) {
  .header-container {
    padding: 2em 2em;
  }
}



