@import '../../../styles/flex.scss'; 
@import '../../../styles/colors.scss'; 

.dashboardMenu-container {
    width: 300px;
    height: 100%;
    background: $color-mid-blue;

    .dashboardMenu-item {
        @include flexContainerRows;
        align-content: center;

        &:hover {
            background: $color-dark-blue;
        }
        a {
            text-decoration: none;
            width: 100%;
            height: 100%;
        }

        .dashboardMenuItem-sort {
            @include flexContainerRows;
            align-items: center;
            color: black;
            margin: 1em 1em 1em 2em;
            font-family: "Poppins", sans-serif;


            .menu-icon {
                font-size: 2em;
                margin-right: 1em;
            }
        }
    }
}

.dashboardMenu-small {
    background: $color-mid-blue;

    .dashboardMenu-item-small {
        @include flexContainerRows;
        align-content: center;

        
        &:hover {
            background: $color-dark-blue;
        }

        a {
            padding: 1em;
            font-size: 2em;
            color: black;
            width: 100%;
            height: 100%;
        }
    }

    // a{
    //     width: 100%;
    //     height: 100%;
    //     .menu-small-icon {
    //         cursor: pointer;
    //         font-size: 2em;
    //         margin-bottom: 2em;
    //         color: black;
 
    //     }

    // }
}