@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

.navigationItem {
    font-family: 'Poppins', sans-serif;
    color: black;
    font-weight: 500;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
}